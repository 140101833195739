export const classList = {
    url: '/api/v1/home/classify', method: 'GET'
}

export const fontClass = {
    url: '/api/v1/font/fontClass', method: 'GET'
}

export const curriculumClass = {
    url: '/api/v1/home/CurriculumClass', method: 'GET'
}

export const isLike = {
    url: '/api/v1/user/like', method: 'POST'
}

export const likeList = {
    url: '/api/v1/user/likeList', method: 'POST'
}

export const getFontItemUrl = {
    url: '/api/v1/font/getFontItemUrl', method: 'POST'
}

export const getItem = { url: '/api/v1/home/getItem', method: 'GET' }

export const getCourseItem = {
    url: '/api/v1/home/getCourseItem', method: 'GET'
}

export const indexSearchList = {
    url: '/api/v1/home/indexSearchList', method: 'GET'
}

export const getFontItem = {
    url: '/api/v1/font/getFontItem', method: 'GET'
}

export const getListSearch = {
    url: '/api/v1/home/search', method: 'GET'
}

export const setComment = {
    url: '/api/v1/comment/set', method: 'POST'
}

export const commentList = {
    url: '/api/v1/comment/list', method: 'GET'
}

export const commentHandle = {
    url: '/api/v1/comment/handle', method: 'POST'
}

export const commentReply = {
    url: '/api/v1/comment/reply', method: 'POST'
}

export const wallpaperList = {
    url: '/api/v1/user/wallpaperList', method: 'GET'
}

export const wallpaperItem = {
    url: '/api/v1/user/wallpaperItem', method: 'GET'
}

export const wallpaperUpdate = {
    url: '/api/v1/user/wallpaperUpdate', method: 'POST'
}

export const wallpaperDel = {
    url: '/api/v1/user/wallpaperDel', method: 'POST'
}

export const createFontItem = {
    url: '/api/v1/font/createFontItem', method: 'GET'
}

export const getItemUrl = {
    url: '/api/v1/home/getItemUrl', method: 'POST'
}

export const photoFrom = {
    url: '/api/v1/search/photoFrom', method: 'GET'
}

export const veerFree = {
    url: '/api/v1/search/veerFree', method: 'GET'
}

export const getList = { url: '/api/v1/home/getList', method: 'GET' }

export const bulletList = { url: '/api/v1/home/bulletList', method: 'GET' }

export const getIndexList = { url: '/api/v1/home/getIndexList', method: 'GET' }

export const getCourseList = {
    url: '/api/v1/home/getCourseList', method: 'GET'
}

export const insert = {
    url: '/api/v1/user/insert', method: 'POST'
}

export const login = {
    url: '/api/v1/user/login', method: 'POST'
}

export const getCustom = {
    url: '/api/v1/user/getCustom', method: 'GET'
}

export const getUserList = {
    url: '/api/v1/user/getUserList', method: 'GET'
}

export const delUserBg = {
    url: '/api/v1/user/delUserBg', method: 'POST'
}

export const updateCustom = {
    url: '/api/v1/user/updateCustom', method: 'POST'
}

export const custom = {
    url: '/api/v1/user/custom', method: 'POST'
}

export const customReset = {
    url: '/api/v1/user/customReset', method: 'POST'
}

export const subscribe = {
    url: '/api/v1/user/subscribe', method: 'POST'
}

export const subscribeList = {
    url: '/api/v1/user/subscribeList', method: 'GET'
}

export const editClass = {
    url: '/api/v1/user/editClass', method: 'POST'
}

export const addClass = {
    url: '/api/v1/user/addClass', method: 'POST'
}

export const qqLogin = {
    url: '/api/v1/user/qqLogin', method: 'GET'
}

export const wxLogin = {
    url: '/api/v1/user/wxLogin', method: 'GET'
}

export const translate = {
    url: '/api/v1/home/translate', method: 'POST'
}

export const exhibition = {
    url: '/api/v1/home/Exhibition', method: 'GET'
}

export const exhibitionClick = {
    url: '/api/v1/home/ExhibitionClick', method: 'POST'
}

export const addLink = {
    url: '/api/v1/user/addLink', method: 'POST'
}

export const delLink = {
    url: '/api/v1/user/delLink', method: 'POST'
}

export const getCourseUrl = {
    url: '/api/v1/home/getCourseUrl', method: 'POST'
}

export const quitLogin = {
    url: '/api/v1/user/quitLogin', method: 'POST'
}

export const colorList = {
    url: '/api/v1/color/getList', method: 'GET'
}

export const colorNameList = {
    url: '/api/v1/color/getNameList', method: 'GET'
}

export const getColorName = {
    url: '/api/v1/color/getColorName', method: 'POST'
}

export const colorItem = {
    url: '/api/v1/color/getItem', method: 'GET'
}

export const getGradualPng = {
    url: '/api/v1/color/getGradualPng', method: 'POST'
}

export const getBgImg = {
    url: '/api/v1/color/getBgImg', method: 'POST'
}

export const colorLike = {
    url: '/api/v1/color/like', method: 'POST'
}

export const colorLikeList = {
    url: '/api/v1/color/likeList', method: 'POST'
}

export const userChecking = {
    url: '/api/v1/user/checking', method: 'POST'
}

export const addKey = {
    url: '/api/v1/home/addKey', method: 'POST'
}

export const searchImg = {
    url: '/api/v1/search/getList', method: 'GET'
}

export const getSunset = {
    url: '/api/v1/home/getSunset', method: 'GET'
}

export const getFontList = {
    url: '/api/v1/font/getList', method: 'GET'
}

export const getWeb = {
    url: '/api/v1/user/getWeb', method: 'GET'
}


