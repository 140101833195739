import styles from './styles/Header.module.scss'
import Link from 'next/link'
import Script from 'next/script'
// import md5 from "js-md5";
import { Row, Col, Menu, message, Button, Modal, Divider, Form, Checkbox, Input, Tabs, Dropdown, Avatar } from 'antd';
import { More } from '@icon-park/react';
import { NextPage } from 'next';
import { State } from '../@types/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import { insert, login, quitLogin } from '../constants/api'
import { http } from '../utils/api'
import { setUserInfo, delUserInfo, setIsModal } from '../redux/actions';
import { useRouter } from 'next/router';
import { menu, nav, title } from '../constants/heads';
import NoSsr from './NoSsr';
const { TabPane } = Tabs;
const { confirm } = Modal;

const Header: NextPage = () => {
  const router = useRouter()
  const { baseUrl, wxConfig, userInfo, isModal } = useSelector((state: State) => state)
  const { isXs, isSm, isMd, isXxl } = useSelector((state: State) => state.responsive)
  const dispatch = useDispatch()
  // const [isModal, setIsModal] = useState(false);
  const [type, setType] = useState('wx');
  const [isMail, setIsMail] = useState('1');
  const onLogout = async () => { //退出登录
    confirm({
      content: '是否确定退出登录？',
      onOk() {
        http(quitLogin).then((res: any) => {
          if (res.code = 200) {
            message.success('退出成功');
            sessionStorage.removeItem('userInfo')
            dispatch(delUserInfo())
            setTimeout(() => {
              router.reload()
            }, 500)
            // if (router.pathname == '/') {
            //   router.reload()
            // } else {
            //   router.push('/')
            //   router.reload()
            // }
          }
        })
      },
      onCancel() {

      },
    });
  }

  const onSignin = async (values: any) => {
    const { decrypted } = (await import('../utils/rsa'))
    const md5 = (await import('js-md5')).default
    const data: any = await http(login, { number: values.number, password: md5(values.password) })
    if (data.code == 200) {
      dispatch(setIsModal(false))
      const user = JSON.parse(decrypted(data.data))
      dispatch(setUserInfo(user))
      sessionStorage.setItem('userInfo', data.data)
      message.success(data.message);
      setTimeout(() => {
        router.reload()
      }, 500)
      // if(router.pathname == '/'){
      //   router.reload()
      // }else{
      //   router.push('/')
      // }
    }
  };

  const onRegister = async (values: any) => {
    const md5 = (await import('js-md5')).default
    const data: any = await http(insert, { number: values.number, mail: values.mail, password: md5(values.password) })
    if (data.code == 200) {
      Modal.info({
        title: null,
        icon: null,
        content: '恭喜您！您的账号已经注册成功，请到您的注册邮件进行激活（七天内未激活我们将清除账号）。如未收到请到垃圾箱查找(国外邮箱可能会有延迟)',
        onOk() {
          setIsMail('1')
        },
      });
    }
  };


  const opened = () => {
    setTimeout(() => {
     const wx:any = new window.WxLogin({
        id: "login_container", // 第三方页面显示二维码的容器id
        appid: wxConfig.AppID, // 应用唯一标识，在微信开放平台提交应用审核通过后获得
        scope: "snsapi_login", // snsapi_login
        redirect_uri: baseUrl + "/api/v1/user/wxLogin",
        href: baseUrl + "/css/wx.css",
      })
    }, 300)
  }

  useEffect(() => {
    if (isModal && type == 'wx') opened()
  }, [isModal, type]);

  useEffect(() => {
    if (router.query.register) {
      dispatch(setIsModal(true))
    }
  }, [router]);
  return (
    <div className={styles.header} style={router.route == '/' ? { position: 'absolute', zIndex: 3, top: 0, width: '100%' } : {}}>
      <Row>
        <Col className="logo" flex={isXs ? '120px' : '160px'}>
          <Link  href="/">
            <img
              src="/logo.png"
              alt={title}
            />
          </Link>
        </Col>
        <Col flex="auto" className={styles.nav}>
          {!isXs ? <Row style={{ width: '408px' }}>
            {nav.map((item, index) => (
              <Col key={index} flex="68px" className={index == 0 ? router.route == '/' ? 'active' : '' : router.route.indexOf(item.href) != -1 ? 'active' : ''}>
                {item.isLink ?
                  <a href={item.href} title={item.title} target="_blank" >{item.title}</a> :
                  <Link href={item.href}>
                    <a title={item.title} >{item.title}</a>
                  </Link>
                }
              </Col>
            ))}
            <NoSsr key="1">
              <Dropdown trigger={['click']} overlay={<Menu>
                {menu.map((item, index) => (
                  item.isLink ?
                    <Menu.Item key={index} title={item.title}>
                      <a href={item.href} target="_blank" title={item.title} >{item.title}</a>
                    </Menu.Item> :
                    <Menu.Item key={index} title={item.title}>
                      <Link href={item.href}>
                        <a title={item.title}>{item.title}</a>
                      </Link>
                    </Menu.Item>
                ))}
              </Menu>}>
                <Col flex="68px">
                  <More theme="outline" size="28" fill="#000000" strokeLinecap="butt" />
                </Col>
              </Dropdown>
            </NoSsr>
          </Row> : <NoSsr key="2"> <Row style={{ width: '136px' }}>
            {nav.map((item, index) => (
              index < 1 && <Col key={index} flex="68px" className={index == 0 ? router.route == '/' ? 'active' : '' : router.route.indexOf(item.href) != -1 ? 'active' : ''}>
                {item.isLink ?
                  <a href={item.href} title={item.title} target="_blank" >{item.title}</a> :
                  <Link href={item.href}>
                    <a title={item.title} >{item.title}</a>
                  </Link>
                }
              </Col>))}
            <Dropdown trigger={['click']} overlay={<Menu> 
              {nav.map((item, index) => (
                index >= 1 && (item.isLink ?
                  <Menu.Item key={index} title={item.title}>
                    <a href={item.href} target="_blank" title={item.title} >{item.title}</a>
                  </Menu.Item> :
                  <Menu.Item key={index} title={item.title}>
                    <Link href={item.href}>
                      <a title={item.title}>{item.title}</a>
                    </Link>
                  </Menu.Item>)
              ))}
              {menu.map((item, index) => (
                item.isLink ?
                  <Menu.Item key={index} title={item.title}>
                    <a href={item.href} target="_blank" title={item.title} >{item.title}</a>
                  </Menu.Item> :
                  <Menu.Item key={index} title={item.title}>
                    <Link href={item.href}>
                      <a title={item.title}>{item.title}</a>
                    </Link>
                  </Menu.Item>
              ))}
            </Menu>}>
              <Col flex="68px">
                <More theme="outline" size="28" fill="#000000" strokeLinecap="butt" />
              </Col>
            </Dropdown>
          </Row></NoSsr>}
        </Col>
        {/* <NoSsr key="3"> */}
        {userInfo.id ?
          <Dropdown trigger={['click']} overlay={
            <Menu>
              <Menu.Item>
                <a>{userInfo.number}</a>
              </Menu.Item>
              <Menu.Item>
                <Link  href='/likes'>
                  <a>
                    我的收藏
                  </a>
                </Link>
              </Menu.Item>
              <Menu.Item>
                <Link  href='/u/subscribe'>
                  <a>
                    我的订阅
                  </a>
                </Link>
              </Menu.Item>
              <Menu.Item>
                <a onClick={onLogout}>
                  退出登录
                </a>
              </Menu.Item>
            </Menu>
          }>
            <Col flex="50px" style={{ textAlign: 'right' }}>
              <Avatar size={isXs ? 30 : 36} src={userInfo.img ? userInfo.img : '/avatar.png'} />
            </Col>
          </Dropdown>
          :
          <Col flex={isXs ? '68px' : '110px'}>
            <Button size={isXs ? "small" : "middle"} className="sign" type="primary" onClick={() => { dispatch(setIsModal(true)) }} shape="round" block>登录</Button>
          </Col>}
          {/* </NoSsr> */}
      </Row>
      <NoSsr key="4">
        <Modal visible={isModal} footer={null} wrapClassName={styles.sign} width={isXs ? 320 : 450} bodyStyle={{padding:isXs?'40px 10px 10px 10px':''}} onCancel={() => dispatch(setIsModal(false))}>
          {type == 'wx' && <div className="wx" >
            <h2>
              <img
                height="30px"
                src="/wx-b.png"
              /> 微信扫码登录/注册
            </h2>
            <p>开启属于你的设计导航</p>
            <div id="login_container"></div>
          </div>
          }
          {type == 'mail' &&
            <Tabs defaultActiveKey="1" size="large" centered activeKey={isMail} onChange={setIsMail}>
              <TabPane tab="登录" key="1">
                <Form
                  name="normal_login"
                  className="login-form"
                  initialValues={{ remember: true }}
                  onFinish={onSignin}
                >
                  <Form.Item
                    name="number"
                    rules={[{ required: true, message: '请输入账号/邮箱', min: 6 }]}
                  >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="账号/邮箱" />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: '请输入密码(长度6-16位)', min: 6, max: 16 }]}
                  >
                    <Input
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="密码"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                      <Checkbox>保持长时间登录</Checkbox>
                    </Form.Item>
                  </Form.Item>

                  <Form.Item>
                    <Button block type="primary" htmlType="submit" className="login-form-button">
                      登录
                    </Button>
                  </Form.Item>
                </Form>
              </TabPane>

              {/* <TabPane tab="注册" key="2">
                <Form
                  name="register"
                  onFinish={onRegister}
                  scrollToFirstError
                >

                  <Form.Item
                    name="number"
                    rules={[{ required: true, message: '请输入账号(长度6-16位)', whitespace: true, min: 6 }]}
                  >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="账号" />
                  </Form.Item>
                  <Form.Item
                    name="mail"
                    rules={[
                      {
                        type: 'email',
                        message: '请输入正确的邮箱',
                      },
                      {
                        required: true,
                        message: '请输入邮箱',
                        min: 6
                      },

                    ]}
                  >
                    <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="邮箱(用于激活账号)" />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: '请输入密码(长度6-16位)',
                        min: 6, max: 16
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="密码" />
                  </Form.Item>

                  <Form.Item
                    name="confirm"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: '请输入确认密码(长度6-16位)',
                        min: 6, max: 16
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                      }),
                    ]}
                  >
                    <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="确认密码" />
                  </Form.Item>


                  <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value ? Promise.resolve() : Promise.reject(new Error('请同意接受协议')),
                      },
                    ]}
                  >
                    <Checkbox>
                      我已阅读并接受<a href="/userAgreemen">用户协议</a>
                    </Checkbox>
                  </Form.Item>
                  <Form.Item >
                    <Button block type="primary" htmlType="submit">
                      注册
                    </Button>
                  </Form.Item>
                </Form>
              </TabPane> */}
            </Tabs>
          }
          <div className="party">
            <Divider>其他方式登录</Divider>
            <Row justify="space-around" align="middle">
              {type != 'wx' && <Col onClick={() => setType('wx')} span={6}>
                <a>
                  <img src="/wx.png" />
                  <p>微信登录</p>
                </a>
              </Col>}
              {type != 'qq' && <Col span={6}>
                <a href={`${baseUrl}/api/v1/user/qqCode`}>
                  <img src="/qq.png" />
                  <p>QQ登录</p>
                </a>
              </Col>}
              {type != 'mail' && <Col onClick={() => setType('mail')} span={6}>
                <a>
                  <img src="/zh.png" />
                  <p>账号登录</p>
                </a>
              </Col>}
            </Row>
          </div>
        </Modal>
      </NoSsr>
    
    </div>
  )
}


export default Header