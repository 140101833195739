import styles from './styles/Footer.module.scss'
import { NextPage } from 'next';
import { useRouter } from 'next/router'
import { Row, Col } from 'antd';
import { State } from '../@types/reducer';
import { useSelector } from 'react-redux';
import { cooperation, copyright, left, link, right } from '../constants/footer';
import NoSsr from './NoSsr';

const Footer: NextPage = () => {
    const router = useRouter()
    const { isXs, isSm, isMd, isXxl } = useSelector((state: State) => state.responsive)
    return (
        <div className={styles.footer} >
            <div className="content">
                <Row gutter={[0, 20]} className="text">
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                        {left}
                    </Col>
                    <NoSsr>
                    {isMd || isXxl &&
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                            {right}
                        </Col>}
                    </NoSsr>
                </Row>
                <NoSsr>
                    {isMd || isXxl && <div className="cooperation">
                        <h4>合作伙伴:</h4>
                        <ul>
                            {cooperation.map((item, index) => (
                                <li key={index}>
                                    <a href={item.href} rel="nofollow" target="_blank">
                                        <img src={item.img} alt={item.name} />
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>}
                </NoSsr>
                {(isMd || isXxl) && router.asPath == '/' ? <div className="link">
                    <p>
                        友情链接:
                        {link.map((item, index) => (<a key={index} href={item.href} target="_blank">{item.name}</a>))}
                    </p>
                </div> : ''}
            </div>
            <div className="copyright">
                {isMd || isXxl && copyright.copyright}&nbsp;<a href="https://beian.miit.gov.cn" rel="nofollow" target="_blank">{copyright.record}</a>
            </div>
        </div>
    )

}

export default Footer