import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import qs from 'qs'
import { baseUrl } from '../../server/config'
import { message } from 'antd';
import { isServer } from '../env-util';
// let baseURL = 'http://127.0.0.1:8800'
axios.defaults.withCredentials = true;
// if (process.env.NODE_ENV === 'production') {
//   baseURL = baseUrl
// }

const instance = axios.create({
  baseURL: baseUrl,
  // timeout: 10000*6,
  withCredentials: true
})

// POST 传参序列化
instance.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 返回状态判断
instance.interceptors.response.use(
  (response: any) => {
    if (!isServer()) {
      if (response.data && response.data.code != 200) {
        message.error(response.data.message);
      }
    }
    return response.data ? response.data : response
  },
  error => {
    return Promise.reject(error)
  }
)


export const http = (option: any, data: any = {}, req?: any) => {
  return instance({
    method: option.method,
    url: option.url,
    data: option.method == 'POST' ? qs.stringify(data) : "",
    params: option.method == 'GET' ? data : "",
    headers: req && req.headers.cookie && { cookie: req.headers.cookie }
  })
}
