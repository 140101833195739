import dynamic from 'next/dynamic'
import React from 'react';
import Header from './Header'
import Footer from './Footer'
import Head from 'next/head'
import { NextPage } from 'next';
import { useRouter } from 'next/router'
import { brief, description, keywords, title } from '../constants/heads'
const DynamicRightBtnNoSSR = dynamic(
  import('./RightBtn'),
  { ssr: false }
)

interface Props {
  head?: {
    title: string,
    keywords: string,
    description: string,
  },
  styles?: string[],
  scripts?: string[],
  className?: string,
  children: React.ReactNode,
}

const Layout: NextPage<Props> = (props) => {
  const { head, styles,scripts, className, children } = props
  return <>
    {
      <Head>
        <title>{head && head.title ? head.title : title + brief}</title>
        <meta name="keywords" content={head && head.keywords ? head.keywords : keywords} />
        <meta name="description" content={head && head.description ? head.description : description} />
        {styles && styles.map((item,index) => (<link key={index} rel="stylesheet" href={item} />))}
        {scripts && scripts.map((item,index) => (<script key={index} src={item} />))}
        <meta name="viewport" content="width=device-width,height=device-height,maximum-scale=1.0,user-scalable=no" key="viewport" />
        {/* {process.env.NODE_ENV !== 'production' && (<link rel="stylesheet" type="text/css" href={'/_next/static/css/styles.chunk.css?v=' + useRouter().route} />)} */}
      </Head>
    }
    <Header />
    <div className={className?className:''}>{children}</div>
    <Footer />
    <DynamicRightBtnNoSSR />
    
  </>
}

export default Layout;