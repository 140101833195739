import { title } from "./heads";

export const left = (<p>
    搜图网，用心做最好用聚合图像搜索引擎
    <br />聚合搜索一站式解决您的搜图需求
    <br />拥有千万级图库聚合搜索
    <br />以及专业的在线配色功能
</p>)

export const gzh = (
    <div style={{ 'textAlign': 'center' }}>
        <img width="180px" src="/wxgzh.jpg" />
        <br />关注公众号领取各种VIP
    </div >
);

export const right = (<>
    <a>
        <p>微信搜索：搜图导航</p>
        <img
            src="/gzh.png"
            alt="搜图公众号"
        />
        <p>只分享优质设计干货</p>
    </a>
    <a>
        <p>抖音号：91sotu</p>
        <img
            src="/dyh.png"
            alt="搜图官方抖音"
        />
        <p>关注获取更多干货</p>
    </a>
    <a
        href="https://qm.qq.com/cgi-bin/qm/qr?k=0j9P-7xr-mAs_0ghx5VpE51GW-04_O1V&jump_from=webapi"
        title="搜图官方QQ群"
        target="_blank"
        rel="noopener noreferrer nofollow"
    >
        <p>群号：734561308</p>
        <img
            src="/qqq.png"
            alt="搜图官方QQ群"
        />
        <p>群里个个都是人才</p>
    </a>
</>
)

export const link = [
    { name: '搜图网', href: 'https://sotuw.com' },
    { name: '搜图', href: 'https://sotuw.com' },
    { name: 'vlog社区', href: 'https://www.vlogxz.com' },
    { name: '视频创作', href: 'https://www.aewz.com' },
    { name: '搜图导航', href: 'https://www.91sotu.com' },
    { name: '在线工具', href: 'https://www.67tool.com' },
]

export const cooperation = [
    { name: '搜图导航', href: 'http://www.91sotu.com', img: '/stdh.png' },
    { name: '简视频', href: 'https://www.aewz.com', img: '/jsp.png' },
    { name: 'vlog小站', href: 'https://www.vlogxz.com', img: '/vlogxz.png' },
]

export const copyright = {
    record: '粤ICP备20050717号',
    copyright: `Copyright ©2021-2022 深圳市搜图信息科技有限公司 |`
}
