import { NextPage } from 'next'
import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react';


const NoSsr: NextPage = (props) => {
  // const [is, setIs] = useState(false)
  // useEffect(() => {
  //   setIs(true)
  // }, []);
  return (<>{props.children}</>)
}


export default dynamic(() => Promise.resolve(NoSsr), { ssr: false })